.notFoundPage {
    padding: 128px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    & h1 {
        font-size: 2.5em;
        margin-bottom: 86px;
        position: relative;
        z-index: 1;
        & small {
            display: block;
            opacity: 0.1;
            font-size: 3.5em;
        }
    }
    & p {
        margin-bottom: 16px;
    }
}
